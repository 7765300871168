<template>
  <div>
    <div class="page-title">
      <div class="row">
        <div class="col-xs-10">
          <h3>Prospects</h3>
          <div class="page-breadcrumb">
            <ol class="breadcrumb">
              <li>
                <router-link
                  :to="{name: 'home'}"
                >
                  Home
                </router-link>
              </li>
              <li>Lead Managements</li>
              <li class="active">
                Prospects
              </li>
            </ol>
          </div>
        </div>
        <div class="col-xs-2 text-right float-right">
          <button
            id="notificationsBell"
            :class="notificationsActive ? 'notifications-enabled' : 'notifications-disabled'"
            class="btn btn-link"
            @click.stop.prevent="toggleNotifications"
          >
            <span class="menu-icon fa-2x fas fa-bell" />
          </button>
        </div>
      </div>
    </div>
    <div id="main-wrapper">
      <div class="row">
        <div class="col-xs-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Prospects
              </h3>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="prospectsList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th class="callbackEpoch">
                        Call Back Time
                      </th>
                      <th class="customerName">
                        Customer Name
                      </th>
                      <th class="mobileNumber">
                        Mobile Number
                      </th>
                      <th class="source">
                        Source
                      </th>
                      <th class="product">
                        Product
                      </th>
                      <th class="remarks">
                        Remarks
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
      <div class="row">
        <div class="col-xs-12">
          <div class="panel panel-white">
            <div class="panel-heading">
              <h3 class="panel-title">
                Call Later
              </h3>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table
                  id="callLaterList"
                  class="table table-striped table-bordered"
                  style="width:100%"
                >
                  <thead>
                    <tr>
                      <th class="callbackEpoch">
                        Call Back Time
                      </th>
                      <th class="customerName">
                        Customer Name
                      </th>
                      <th class="mobileNumber">
                        Mobile Number
                      </th>
                      <th class="source">
                        Source
                      </th>
                      <th class="product">
                        Product
                      </th>
                      <th class="remarks">
                        Remarks
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div><!-- Row -->
    </div>
    <div
      id="leadModal"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span
                aria-hidden="true"
              >&times;</span>
            </button>
            <h3 class="modal-title text-center">
              Lead Details
            </h3>
          </div>
          <div class="modal-body">
            <div
              class="row"
              style="margin-top: 25px"
            >
              <div class="form-group col-xs-offset-1 col-xs-5">
                <label class="form-details-label">Source:</label>
                <p class="text-muted">
                  {{ prettyPrint(leadDetails.source, 'Source') }}
                </p>
              </div>
              <div class="form-group col-xs-offset-1 col-xs-4">
                <label class="form-details-label">Product:</label>
                <p class="text-muted">
                  {{ prettyPrint(leadDetails.product, 'Product') }}
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-if="leadDetails.customerName && leadDetails.customerName !== ''"
                class="form-group col-xs-offset-1 col-xs-5"
              >
                <label class="form-details-label">Customer Name:</label>
                <p class="text-muted">
                  {{ leadDetails.customerName }}
                </p>
              </div>
              <div class="form-group col-xs-offset-1 col-xs-4">
                <label class="form-details-label">Mobile Number:</label>
                <p>
                  <a
                    :href="'tel:' + getCountryCode + leadDetails.mobileNumber "
                    class="text-muted"
                  >{{ leadDetails.mobileNumber }}</a>
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-if="leadDetails.email && leadDetails.email !== ''"
                class="form-group col-xs-offset-1 col-xs-5"
              >
                <label class="form-details-label">Email:</label>
                <p class="text-muted">
                  {{ leadDetails.email }}
                </p>
              </div>
              <div
                v-if="leadDetails.pancard && leadDetails.pancard !== ''"
                class="form-group col-xs-offset-1 col-xs-4"
              >
                <label class="form-details-label">Pancard:</label>
                <p class="text-muted">
                  {{ leadDetails.pancard }}
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-if="leadDetails.address && leadDetails.address !== ''"
                class="form-group col-xs-offset-1 col-xs-10"
              >
                <label class="form-details-label">Address:</label>
                <p class="text-muted">
                  {{ leadDetails.address }}
                </p>
              </div>
            </div>
            <div class="row">
              <div
                v-if="leadDetails.city && leadDetails.city !== ''"
                class="form-group col-xs-offset-1 col-xs-10"
              >
                <label class="form-details-label">City:</label>
                <p class="text-muted">
                  {{ leadDetails.city }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-xs-offset-1 col-xs-10">
                <label class="has-float-label">
                  <VueMultiselect
                    v-model="leadDetails.interestedProducts"
                    :allow-empty="true"
                    :class="{ 'placeholder-shown': (!leadDetails.interestedProducts || leadDetails.interestedProducts === '') }"
                    :multiple="true"
                    :options="_getOtherProducts"
                    :placeholder="null"
                    :searchable="false"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                  <span class="float-label">Interested In Other</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div
                v-if="allowRemarks"
                class="form-group col-xs-offset-1 col-xs-10"
              >
                <label class="has-float-label">
                  <input
                    v-model="leadDetails.remarks"
                    placeholder=" "
                    type="text"
                  >
                  <span class="float-label">Remarks</span>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-xs-offset-1 col-xs-10">
                <label class="has-float-label">
                  <VueMultiselect
                    v-model="leadDetails.feedback"
                    :allow-empty="false"
                    :class="{ 'placeholder-shown': (!leadDetails.feedback || leadDetails.feedback === '') }"
                    :multiple="false"
                    :options="_getFeedbackOptions"
                    :placeholder="null"
                    :searchable="false"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                  <span class="float-label">Feedback</span>
                </label>
              </div>
            </div>
            <div
              v-if="isCallbackTimeRequired"
              class="row"
            >
              <div class="form-group col-xs-offset-1 col-xs-10">
                <label class="has-float-label">
                  <VueMultiselect
                    v-model="leadDetailsCallbackPreset"
                    :allow-empty="false"
                    :class="{ 'placeholder-shown': (!leadDetailsCallbackPreset || leadDetailsCallbackPreset === '') }"
                    :multiple="false"
                    :options="_getCallbackPresets"
                    :placeholder="null"
                    :searchable="false"
                    :show-labels="false"
                    label="name"
                    track-by="id"
                  />
                  <span class="float-label">Call Back Time</span>
                </label>
              </div>
              <date-range-picker
                v-if="leadDetailsCallbackPreset && leadDetailsCallbackPreset.id === 'Custom'"
                ref="picker"
                v-model="leadDetailsCallback"
                :locale-data="getLocaleData"
                :min-date="getDateToday"
                :opens="'right'"
                :ranges="false"
                :single-date-picker="true"
                :time-picker="true"
                :time-picker-increment="1"
                :time-picker24hour="false"
                class="form-group col-xs-offset-1 col-xs-10"
              >
                <div
                  slot="input"
                  slot-scope="picker"
                  style="min-width: 250px;"
                >
                  {{ picker.startDate | date }}
                </div>
              </date-range-picker>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="leadDetails && leadDetails !== ''"
              id="createForm"
              class="btn btn-default btn-success pull-left"
              type="button"
              @click.stop.prevent="createForm"
            >
              {{ 'Create ' + prettyPrint(leadDetails.product, 'Product') + ' Form' }}
            </button>
            <button
              class="btn btn-default"
              data-dismiss="modal"
              type="button"
            >
              Close
            </button>
            <button
              id="updateLead"
              class="btn btn-default btn-warning"
              type="button"
              @click.stop.prevent="updateLead"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from "vuex";
import {API, graphqlOperation} from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import {leadFeedbackCategories, pushNotificationCategories} from "@/constants";
import VueMultiselect from "vue-multiselect";
import DateRangePicker from "vue2-daterange-picker";

export default {
  name: "Prospects",
  components: {
    VueMultiselect,
    DateRangePicker
  },
  filters: {
    date: function (date) {
      return moment(date).format('dddd, MMMM Do hh:mm A');
    }
  },
  data() {
    return {
      prospectsTable: null,
      prospectsTableData: [],
      callLaterTable: null,
      callLaterTableData: [],
      leadDetails: {},
      leadDetailsCallback: {
        startDate: moment().subtract(1, 'hours').startOf('hour')
      },
      leadDetailsCallbackPreset: null,
      notificationsActive: false
    }
  },
  beforeRouteLeave(to, from, next) {
    let leadModal = $("#leadModal");
    if ((leadModal.data('bs.modal') || {}).isShown) {
      leadModal.modal('hide');
      document.getElementById('prospectsList').scrollIntoView({behavior: 'instant'});
      next(false);
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      "getLeads",
      "getLoggedInUser",
      "getUsers",
      "getProducts",
      "getLeadFeedbacks",
      "getEnabledSources",
      "getSettings"
    ]),
    ...mapState([
      'leads',
    ]),
    _getFeedbackOptions: function () {
      let excludeInterested = this.leadDetails && this.leadDetails.feedback && this.leadDetails.feedback.category === leadFeedbackCategories.INTERESTED;
      return _.sortBy(_.filter(this.getLeadFeedbacks, (feedback) => {
        return feedback.category !== leadFeedbackCategories.CLOSED
            && _.includes(feedback.products, this.leadDetails.product)
            && (!excludeInterested || feedback.category !== leadFeedbackCategories.INTERESTED);
      }), 'name');
    },
    isCallbackTimeRequired: function () {
      return this.leadDetails && this.leadDetails.feedback && !_.includes([leadFeedbackCategories.REUSABLE, leadFeedbackCategories.UNUSABLE], this.leadDetails.feedback.category);
    },
    getLocaleData() {
      return {
        format: 'dddd, MMMM Do hh:mm A',
        firstDay: moment.localeData().firstDayOfWeek()
      }
    },
    getDateToday() {
      return moment().format('LL');
    },
    _getCallbackPresets() {
      return [
        {id: 'HalfHour', name: 'In 30 Minutes'},
        {id: '1Hour', name: 'In 1 Hour'},
        {id: '2Hours', name: 'In 2 Hours'},
        {id: '6Hours', name: 'In 6 Hours'},
        {id: '12Hours', name: 'In 12 Hours'},
        {id: '24Hours', name: 'In 24 Hours'},
        {id: 'Evening', name: 'In the Evening'},
        {id: 'Morning', name: 'Tomorrow Morning'},
        {id: 'Custom', name: 'Custom'}
      ];
    },
    allowRemarks() {
      return this.leadDetails && this.leadDetails.feedback && !_.includes([leadFeedbackCategories.REUSABLE, leadFeedbackCategories.UNUSABLE], this.leadDetails.feedback.category);
    },
    _getOtherProducts() {
      return _.sortBy(_.filter(this.getProducts, (product) => {
        return !this.leadDetails || (product.id !== this.leadDetails.product);
      }), 'name');
    },
    getCountryCode() {
      const countryCodeSetting = _.find(this.getSettings, ['name', 'countryCode']);
      if (countryCodeSetting && countryCodeSetting.value) {
        return countryCodeSetting.value;
      } else {
        return '';
      }

    }
  },
  watch: {
    leads(newValue) {
      let prospects = _.filter(newValue, (lead) => {
        return lead.isInterested === 'YES';
      });
      this.prospectsTable.clear()
          .draw();
      if (prospects.length > 0) {
        this.prospectsTableData = prospects;
        this.prospectsTable.rows
            .add(prospects)
            .draw();
      }

      let callLater = _.filter(newValue, (lead) => {
        return lead.isCallLater === 'YES';
      });
      this.callLaterTable.clear()
          .draw();
      if (callLater.length > 0) {
        this.callLaterTableData = callLater;
        this.callLaterTable.rows
            .add(callLater)
            .draw();
      }
    },
    leadDetailsCallbackPreset(newValue) {
      if (!newValue || !newValue.id || newValue.id === '') {
        return;
      }
      if (newValue.id === 'HalfHour') {
        this.leadDetailsCallback = {
          startDate: moment().add(30, 'minutes')
        };
      } else if (newValue.id === '1Hour') {
        this.leadDetailsCallback = {
          startDate: moment().add(1, 'hours')
        };
      } else if (newValue.id === '2Hours') {
        this.leadDetailsCallback = {
          startDate: moment().add(2, 'hours')
        };
      } else if (newValue.id === '6Hours') {
        this.leadDetailsCallback = {
          startDate: moment().add(6, 'hours')
        };
      } else if (newValue.id === '12Hours') {
        this.leadDetailsCallback = {
          startDate: moment().add(12, 'hours')
        };
      } else if (newValue.id === '24Hours') {
        this.leadDetailsCallback = {
          startDate: moment().add(24, 'hours')
        };
      } else if (newValue.id === 'Evening') {
        this.leadDetailsCallback = {
          startDate: moment().startOf('day').add(18, 'hours')
        };
      } else if (newValue.id === 'Morning') {
        this.leadDetailsCallback = {
          startDate: moment().add(1, 'days').startOf('day').add(9, 'hours')
        };
      }
    }
  },
  mounted() {
    $('#sidebar').removeClass('visible');
    $('.page-inner').removeClass('sidebar-visible');

    let pushSubscriptionObject = JSON.parse(window.localStorage.getItem('pushSubscription' + pushNotificationCategories.PROSPECTS));
    this.notificationsActive = pushSubscriptionObject && pushSubscriptionObject.endpoint && pushSubscriptionObject.endpoint !== '';

    this.prospectsTableData = _.filter(this.getLeads, (lead) => {
      return lead.isInterested === 'YES';
    });
    this.callLaterTableData = _.filter(this.getLeads, (lead) => {
      return lead.isCallLater === 'YES';
    });
    this.createDatatable(leadFeedbackCategories.INTERESTED);
    this.createDatatable(leadFeedbackCategories.CALL_LATER);
  },
  methods: {
    ...mapMutations([
      "UPDATE_LEADS",
      "UPDATE_LOGGED_IN_USER"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'Epoch') {
        if (value && value !== '') {
          return moment(value, 'X').format('MMMM Do YYYY h:mm A');
        } else {
          return value;
        }
      } else if (type === 'Source') {
        let sourceName = _.result(_.find(this.getEnabledSources, (source) => {
          return source.id === value;
        }), 'name');
        if (sourceName) {
          return sourceName;
        } else {
          return '';
        }
      } else if (type === 'Product') {
        let productName = _.result(_.find(this.getProducts, (product) => {
          return product.id === value;
        }), 'name');
        if (productName) {
          return productName;
        } else {
          return '';
        }
      }
    },
    async toggleNotifications() {
      if (!navigator.serviceWorker) {
        this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        return;
      }
      let registrations = await navigator.serviceWorker.getRegistrations();
      if (registrations.length < 1) {
        console.error('Error fetching service worker.', 'Service worker not registered.');
        this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        return;
      }
      let notificationsButton = $('#notificationsBell');
      notificationsButton.attr('disabled', true);
      if (this.notificationsActive) {
        try {
          let pushSubscriptions = _.filter(this.getLoggedInUser.pushSubscriptions, (subscription) => {
            let subscriptionObject = JSON.parse(subscription) || {};
            return subscriptionObject.type !== pushNotificationCategories.PROSPECTS;
          });
          if (pushSubscriptions.length < 1) {
            pushSubscriptions = null;
          }
          await API.graphql(graphqlOperation(mutations.updateUser, {
            input: {
              id: this.getLoggedInUser.id,
              pushSubscriptions: pushSubscriptions
            }
          }));
          window.localStorage.removeItem('pushSubscription' + pushNotificationCategories.PROSPECTS);
          let updatedUser = _.cloneDeep(this.getLoggedInUser);
          updatedUser.pushSubscriptions = pushSubscriptions;
          this.UPDATE_LOGGED_IN_USER(updatedUser);
          this.setMessage('v-notify-success', 'Done!', "Notifications are now disabled!");
          this.notificationsActive = false;
        } catch (error) {
          // Unsubscription failed
          console.error('Unable to unsubscribe from push.', error);
          this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        } finally {
          notificationsButton.attr('disabled', false);
        }
      } else {
        try {
          let serviceWorkerRegistration = await navigator.serviceWorker.ready;
          try {
            let options = {
              userVisibleOnly: true,
              applicationServerKey: 'BP8qzHt90VAyEoOziUULbe2BmqbysxNPVg9xfGsjLsKXNEG_YnX6rJ8A7zVqzRWj-36obFN9N61tiCq_H69mMAA'
            };
            let pushSubscription = await serviceWorkerRegistration.pushManager.subscribe(options);
            let currentObject = pushSubscription.toJSON();
            window.localStorage.setItem('pushSubscription' + pushNotificationCategories.PROSPECTS, JSON.stringify(currentObject));
            let serverObject = JSON.parse(_.find(this.getLoggedInUser.pushSubscriptions, (subscription) => {
              let subscriptionObject = JSON.parse(subscription) || {};
              return subscriptionObject.type === pushNotificationCategories.PROSPECTS;
            }) || null);
            if (!serverObject || currentObject.endpoint !== serverObject.endpoint || currentObject.keys['p256dh'] !== serverObject.keys['p256dh'] || currentObject.keys.auth !== serverObject.keys.auth) {
              currentObject.type = pushNotificationCategories.PROSPECTS;
              let pushSubscriptions = _.filter(this.getLoggedInUser.pushSubscriptions, (subscription) => {
                let subscriptionObject = JSON.parse(subscription) || {};
                return subscriptionObject.type !== pushNotificationCategories.PROSPECTS;
              });
              pushSubscriptions.push(JSON.stringify(currentObject));
              await API.graphql(graphqlOperation(mutations.updateUser, {
                input: {
                  id: this.getLoggedInUser.id,
                  pushSubscriptions: pushSubscriptions
                }
              }));
              let updatedUser = _.cloneDeep(this.getLoggedInUser);
              updatedUser.pushSubscriptions = pushSubscriptions;
              this.UPDATE_LOGGED_IN_USER(updatedUser);
            }
            this.setMessage('v-notify-success', 'All Set!', "Notifications enabled successfully.");
            this.notificationsActive = true;
          } catch (error) {
            console.log("Error during subscription");
            console.log(error);
            if (Notification.permission === 'denied') {
              console.warn('Permission for notifications was denied');
              this.setMessage('v-notify-error', "Error!", 'Notifications are disabled! Please enable them from your browser.');
            } else {
              console.error('Unable to subscribe to push', error);
              this.setMessage('v-notify-error', "Error!", 'Unable to subscribe to notifications at this time! Try again later.');
            }
          }
        } catch (error) {
          console.error("Error during fetching service worker", error);
          this.setMessage('v-notify-error', "Error!", 'Something went wrong! Please try again later.');
        } finally {
          notificationsButton.attr('disabled', false);
        }
      }
    },
    createDatatable: function (type) {
      let dataTableOptions = {
        processing: true,
        language: {
          'loadingRecords': '&nbsp;',
          'processing': '<i class="fas fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
        },
        dom: 'Bfrtip',
        lengthMenu: [
          [10, 25, 50, 100, -1],
          ['10 rows', '25 rows', '50 rows', '100 rows', 'Show all']
        ],
        data: type === leadFeedbackCategories.INTERESTED ? this.prospectsTableData : this.callLaterTableData,
        rowId: 'id',
        columns: [
          {
            data: 'callbackEpoch',
            defaultContent: ''
          },
          {
            data: 'customerName',
            defaultContent: ""
          },
          {
            data: 'mobileNumber'
          },
          {
            data: 'source',
            defaultContent: ''
          },
          {
            data: 'product',
            defaultContent: ''
          },
          {
            data: 'remarks',
            defaultContent: ''
          }
        ],
        columnDefs: [
          {
            render: (data) => {
              return this.prettyPrint(data, 'Epoch');
            },
            targets: 'callbackEpoch'
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Source');
            },
            targets: 'source'
          },
          {
            render: (data) => {
              return this.prettyPrint(data, 'Product');
            },
            targets: 'product'
          }
        ],
        buttons: [
          {
            extend: 'pageLength',
            text: '<i class="fas fa-filter"></i>',
            titleAttr: 'Number of Rows'
          }
        ],
        search: {
          regex: true,
          smart: false
        },
        order: [[0, "asc"]]
      };
      if (type === leadFeedbackCategories.INTERESTED) {
        this.prospectsTable = $('#prospectsList').DataTable(dataTableOptions);
        this.prospectsTable.buttons()
            .container()
            .appendTo('#prospectsList_wrapper .col-sm-6:eq(0)');
      } else {
        this.callLaterTable = $('#callLaterList').DataTable(dataTableOptions);
        this.callLaterTable.buttons()
            .container()
            .appendTo('#callLaterList_wrapper .col-sm-6:eq(0)');

      }
      this.setupLeadModal(type);
    },
    setupLeadModal: function (type) {
      $((type === leadFeedbackCategories.INTERESTED ? '#prospectsList' : '#callLaterList') + ' tbody').on('click', 'tr', (e) => {
        this.leadDetails = {};
        if (type === leadFeedbackCategories.INTERESTED) {
          this.leadDetails = _.cloneDeep(this.prospectsTable.row(e.currentTarget).data());
        } else {
          this.leadDetails = _.cloneDeep(this.callLaterTable.row(e.currentTarget).data());
        }
        if (this.leadDetails) {
          this.leadDetails.feedback = _.find(this.getLeadFeedbacks, (feedback) => {
            return feedback.id === this.leadDetails.feedback;
          }) || '';
          this.leadDetails.interestedProducts = _.filter(this.getProducts, (product) => {
            return _.includes(this.leadDetails.interestedProducts, product.id);
          });
          $("#leadModal").modal('show');
        } else {
          this.leadDetails = {};
        }
      });
    },
    createForm: function () {
      let createButton = $('#createForm ');
      let updateButton = $('#updateLead');
      createButton.attr('disabled', true);
      updateButton.attr('disabled', true);
      this.setMessage('confirm', 'Are you sure?', 'You are about to create a new form for this lead!').then((data) => {
        if (data && data.data) {
          if (_.includes(['c03143c4-bcfb-4526-a86f-ca29fc402b78', '597c5c1a-bc21-47c9-a69d-69a4c68f73ab', '2a32cec2-1d11-48a6-84aa-9a8c9fd33c12'], this.leadDetails.product)) {
            this.createNewForm();
          } else {
            this.temporaryLeadClosedUpdate();
          }
        } else {
          createButton.attr('disabled', false);
          updateButton.attr('disabled', false);
        }
      });
    },
    async createNewForm() {
      let createLeadInfo = _.cloneDeep(this.leadDetails);
      this.leadDetails = {};
      this.leadDetailsCallback = {
        startDate: moment().subtract(1, 'hours').startOf('hour')
      };
      this.leadDetailsCallbackPreset = null;
      $("#leadModal").modal('hide');
      this.setMessage('v-notify-info', 'Please Wait!', "You will now be re-directed to the form entry page.");
      $('body').removeClass('loaded');
      this.$router.push({
        name: 'newForm',
        params: {
          productId: createLeadInfo.product,
          leadId: createLeadInfo.id,
          mobileNumber: createLeadInfo.mobileNumber,
          expectedVersion: createLeadInfo.version
        }
      }, () => {
        $('body').addClass('loaded');
      }, (err) => {
        $('body').addClass('loaded');
        this.setMessage('v-notify-error', 'Sorry!', err);
      });
    },
    async temporaryLeadClosedUpdate() {
      let createButton = $('#createForm ');
      let updateButton = $('#updateLead');
      const currentTimeEpoch = moment().format('X');
      try {
        let newLead = {
          id: this.leadDetails.id,
          callbackEpoch: null,
          isAssigned: 'NA',
          assignedTo: null,
          isClosed: 'YES',
          isNoContact: 'NO',
          isInterested: 'NO',
          isReusable: 'NO',
          isUnusable: 'NO',
          isCallLater: 'NO',
          noContactAttempt: null,
          remarks: null,
          updatedAtEpoch: currentTimeEpoch,
          expectedVersion: this.leadDetails.version
        };
        let closedFeedback = _.find(this.getLeadFeedbacks, (feedback) => {
          return feedback.category === leadFeedbackCategories.CLOSED && _.includes(feedback.products, this.leadDetails.product);
        });
        if (closedFeedback && closedFeedback !== '') {
          newLead.feedback = closedFeedback.id;
          const {
            data: {updateLead}
          } = await API.graphql(graphqlOperation(mutations.updateLead, {
            input: newLead
          }));
          this.createLog(updateLead.id, updateLead.feedback);

          this.prospectsTable.row("#" + updateLead.id)
              .remove()
              .draw();
          this.callLaterTable.row("#" + updateLead.id)
              .remove()
              .draw();

          let leadIdx = _.findIndex(this.prospectsTableData, (oldLead) => {
            return oldLead.id === updateLead.id;
          });
          if (leadIdx !== -1) {
            _.remove(this.prospectsTableData, (lead) => {
              return lead.id === updateLead.id;
            });
          }

          leadIdx = _.findIndex(this.callLaterTableData, (oldLead) => {
            return oldLead.id === updateLead.id;
          });
          if (leadIdx !== -1) {
            _.remove(this.callLaterTableData, (lead) => {
              return lead.id === updateLead.id;
            });
          }

          let oldLeads = _.cloneDeep(this.getLeads);
          let leadIndex = _.findIndex(oldLeads, (oldLead) => {
            return oldLead.id === updateLead.id;
          });
          if (leadIndex !== -1) {
            _.remove(oldLeads, (lead) => {
              return lead.id === updateLead.id;
            });
            this.UPDATE_LEADS(oldLeads);
          }
          this.leadDetails = {};
          this.leadDetailsCallback = {
            startDate: moment().subtract(1, 'hours').startOf('hour')
          };
          this.leadDetailsCallbackPreset = null;
          createButton.attr('disabled', false);
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-success', 'Nice!', "Lead Closed!");
          $("#leadModal").modal('hide');
        } else {
          createButton.attr('disabled', false);
          updateButton.attr('disabled', false);
          this.setMessage('v-notify-error', 'Sorry!', 'Unable to close lead at this time. Try again later!');
        }
      } catch (err) {
        createButton.attr('disabled', false);
        updateButton.attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    },
    async updateLead() {
      let createButton = $('#createForm ');
      let updateButton = $('#updateLead');
      const currentTimeEpoch = moment().format('X');
      if (!this.leadDetails.feedback || this.leadDetails.feedback === '') {
        this.setMessage('v-notify-error', 'Oops!', "Lead feedback must be entered!");
        return;
      }
      if (this.isCallbackTimeRequired &&
          (!this.leadDetailsCallback
              || !this.leadDetailsCallback.startDate
              || (_.parseInt(moment(this.leadDetailsCallback.startDate, 'dddd, MMMM Do hh:mm A').format('X')) <= currentTimeEpoch))) {
        this.setMessage('v-notify-error', 'Sorry!', "Must select a valid call back later time!");
        return;
      }
      try {
        createButton.attr('disabled', true);
        updateButton.attr('disabled', true);
        let newLead = {
          id: this.leadDetails.id,
          feedback: this.leadDetails.feedback.id,
          callbackEpoch: this.isCallbackTimeRequired ? _.parseInt(moment(this.leadDetailsCallback.startDate, 'dddd, MMMM Do hh:mm A').format('X')) : null,
          remarks: this.allowRemarks && this.leadDetails.remarks && this.leadDetails.remarks !== '' ? this.leadDetails.remarks : null,
          updatedAtEpoch: currentTimeEpoch,
          expectedVersion: this.leadDetails.version
        };
        if (_.includes([leadFeedbackCategories.UNUSABLE, leadFeedbackCategories.REUSABLE], this.leadDetails.feedback.category)) {
          newLead.isReusable = this.leadDetails.feedback.category === leadFeedbackCategories.REUSABLE ? 'YES' : 'NO';
          newLead.isUnusable = this.leadDetails.feedback.category === leadFeedbackCategories.UNUSABLE ? 'YES' : 'NO';
          newLead.isCallLater = 'NO';
          newLead.isClosed = 'NO';
          newLead.isInterested = 'NO';
          newLead.isNoContact = 'NO';
          newLead.assignedTo = null;
          newLead.isAssigned = 'NA';
        }
        if (this.leadDetails.feedback.category === leadFeedbackCategories.CALL_LATER) {
          newLead.isReusable = 'NO';
          newLead.isUnusable = 'NO';
          newLead.isCallLater = 'YES';
          newLead.isClosed = 'NO';
          newLead.isInterested = 'NO';
          newLead.isNoContact = 'NO';
        } else if (this.leadDetails.feedback.category === leadFeedbackCategories.INTERESTED) {
          newLead.isReusable = 'NO';
          newLead.isUnusable = 'NO';
          newLead.isCallLater = 'NO';
          newLead.isClosed = 'NO';
          newLead.isInterested = 'YES';
          newLead.isNoContact = 'NO';
        }
        if (this.leadDetails.interestedProducts && this.leadDetails.interestedProducts.length > 0) {
          newLead.interestedProducts = _.map(this.leadDetails.interestedProducts, 'id');
          newLead.isInterestedOther = 'YES';
          newLead.referralOwner = this.getLoggedInUser.username;
        } else {
          newLead.interestedProducts = null;
          newLead.isInterestedOther = null;
          newLead.referralOwner = null;
        }
        const {
          data: {updateLead}
        } = await API.graphql(graphqlOperation(mutations.updateLead, {
          input: newLead
        }));
        this.createLog(updateLead.id, updateLead.feedback);

        this.prospectsTable.row("#" + updateLead.id)
            .remove()
            .draw();
        this.callLaterTable.row("#" + updateLead.id)
            .remove()
            .draw();

        let leadIdx = _.findIndex(this.prospectsTableData, (oldLead) => {
          return oldLead.id === updateLead.id;
        });
        if (leadIdx !== -1) {
          _.remove(this.prospectsTableData, (lead) => {
            return lead.id === updateLead.id;
          });
        }

        leadIdx = _.findIndex(this.callLaterTableData, (oldLead) => {
          return oldLead.id === updateLead.id;
        });
        if (leadIdx !== -1) {
          _.remove(this.callLaterTableData, (lead) => {
            return lead.id === updateLead.id;
          });
        }

        let oldLeads = _.cloneDeep(this.getLeads);
        let leadIndex = _.findIndex(oldLeads, (oldLead) => {
          return oldLead.id === updateLead.id;
        });
        if (leadIndex !== -1) {
          if (updateLead.isInterested === 'YES' || updateLead.isCallLater === 'YES') {
            oldLeads[leadIndex] = updateLead;
            this.UPDATE_LEADS(oldLeads);
          } else {
            _.remove(oldLeads, (lead) => {
              return lead.id === updateLead.id;
            });
            this.UPDATE_LEADS(oldLeads);
          }
        }
        this.leadDetails = {};
        this.leadDetailsCallback = {
          startDate: moment().subtract(1, 'hours').startOf('hour')
        };
        this.leadDetailsCallbackPreset = null;
        createButton.attr('disabled', false);
        updateButton.attr('disabled', false);
        this.setMessage('v-notify-success', 'Nice!', "Lead updated.");
        $("#leadModal").modal('hide');
      } catch (err) {
        createButton.attr('disabled', false);
        updateButton.attr('disabled', false);
        this.setMessage('v-notify-error', 'Sorry!', err);
      }
    },
    async createLog(leadId, action) {
      const currentTimeEpoch = moment().format('X');
      let retentionDays = moment(currentTimeEpoch, 'X').add(30, 'days');
      const retentionSetting = _.find(this.getSettings, ['name', 'leadHistoryRetention']);
      if (retentionSetting && retentionSetting.value) {
        try {
          retentionDays = moment(currentTimeEpoch, 'X').add(_.parseInt(retentionSetting.value), 'days');
        } catch (e) {
          retentionDays = moment(currentTimeEpoch, 'X').add(30, 'days');
        }
      }
      try {
        let supervisor = _.find(this.getUsers, (user) => {
          return user.id === this.getLoggedInUser.supervisor;
        });
        let manager = {};
        if (supervisor) {
          manager = _.find(this.getUsers, (user) => {
            return user.id === supervisor.supervisor;
          });
        }
        await API.graphql(graphqlOperation(mutations.createLog, {
          input: {
            logLeadId: leadId,
            action: "LEAD",
            supervisor: _.result(supervisor, 'username'),
            branchManager: _.result(manager, 'username'),
            actionType: action,
            actionEpoch: currentTimeEpoch,
            expirationEpoch: _.parseInt(retentionDays.format('X'))
          }
        }));
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style scoped>

</style>
